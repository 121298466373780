.green {
    color: green;
}

.red {
    color: red;
}

.my-striped-table tbody tr:nth-child(odd) {
  background:rgb(250, 250, 250);
 }

.ticker-img {
   height: 20px;
   width: 30px;
   float:left;
  }
  .ticker-img-title {
   display: inline-block;
   /* height:50px; */
   vertical-align:text-bottom;
}

.ticker-img img, .ticker-img-title img {
  /* width: 100%; */
  /* width:30px; */
  height: 100%;
  border-radius: 10%;
  border:0.5px solid rgb(230, 230, 230);
  margin-inline: 10px;
}

.ticker-img-container {
  display: flex;
  align-items: center;
}

.left-img,.right-text {
  flex: 50%;
}

.right-text{
  text-align: left;
}

.center-card {
    /* margin: auto; */
    /* width:95%; */
    padding: 5px;
    display:inline-block;
    vertical-align: top;
    justify-content: center; 
    align-items:center;
  }

.small-table td, .small-table th {
    padding: 0.1vw calc(2px + 0.5vw);
    border: 1px solid black;
    text-align:center;
  }
.small-table td{
  word-wrap: break-word;
}
.small-table {
    /* width: 100%; */
    max-width: 90%; /* 20px = 10px padding on both sides */
    margin: 4px calc(5px + 0.5vw);
    table-layout:auto;
    border-collapse: collapse;
    border-style: hidden;
  }

.loader {
    left: 50%;
    margin-left: -4em;
    margin: 2em;
  }

.ant-card-body{
  padding:5px !important; 
}

@media (max-width: 600px) {
      .ant-card-grid {
        width: 100% !important;
        /* min-width: 200px !important; */
      }
    }
@media (max-width: 800px) and (min-width:600px) {
    .ant-card-grid {
      width: 50% !important;
    }
  }