.homepage-header {
    margin-top: 100px;  /* Adjust as needed */
    margin-bottom: 100px !important;  /* Adjust as needed */
    text-align: center;
  }

  .content-wrapper {
    margin-left: 30px; /* Adjust as needed */
    margin-right: 30px; /* Adjust as needed */
  }

  .homepage-image {
    width: 80%;
    height: auto;
  }